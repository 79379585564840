// Custom colors in bootstrap
$primary: #7dc242;
$light: #bdeb96;
$medium: #66aa2c;
$dark: #295406;
$darkYellow: #c79100;
$primaryYellow: #ffc107;
$lightYellow: #fff350;
$darkRed: #870000;
$primaryRed: #bf360c;
$lightRed: #f9683a;

$theme-colors: (
  primary: $primary,
  light: $light,
  dark: $dark,
  danger: $primaryRed,
  warning: $primaryYellow,
);

// Import bootstrap
@import '~bootstrap/scss/bootstrap.scss';